@import '../../mixins';

.mobile-menu {
  --mm-color-background: var(--bg-white);
  --mm-color-border: var(--stroke-dark);
  --mm-color-icon: var(--elements-gray);
  --mm-color-text: var(--text-dark-primary);
  --mm-color-text-dimmed: var(--text-dark-primary);
  --mm-color-background-highlight: var(--bg-light);
  --mm-color-background-emphasis: transparent;
  --mm-color-focusring: var(--accent-color);
  display: none;

  @include mediaTablet {
    top: rem(84);

    width: 100%;
    max-width: rem(384);
    height: calc(100vh - #{rem(84)});
    display: flex;
  }

  @include mediaMobile {
    top: rem(64);

    height: calc(100vh - #{rem(64)});
    max-width: none;
  }

  &__list {
    padding: rem(10) rem(15) 0;

    @include mediaBigDesktop {
      padding: big(10) big(15) 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    &::after {
      inset-inline-start: 0;
    }
  }

  &__link {
    padding: rem(11.5) 0;
    margin: 0;

    color: var(--text-dark-primary);
    font-size: rem(14);
    line-height: 1.5;

    @include mediaBigDesktop {
      padding: big(11.5) 0;

      font-size: big(14);
    }

    &_special.mm-listitem__text {
      color: var(--system-states-error);
    }
  }

  &__custom {
    --mm-navbar-size: -1px;
    position: relative;
    left: rem(-15);
    padding: rem(10) rem(15) rem(40);
    margin: 0;

    width: calc(100% + #{rem(30)});

    @include mediaBigDesktop {
      left: big(-15);
      padding: big(10) big(15) big(40);
      width: calc(100% + #{big(30)});
    }

    & .nav {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 0;

      &__link {
        padding: rem(11.5) 0;
        color: var(--text-dark-secondary);

        width: 100%;
        display: flex;

        text-transform: initial;
        text-align: start;
        white-space: normal;

        border-bottom: solid 1px var(--stroke-dark);

        @include mediaBigDesktop {
          padding: big(11.5) 0;

          border-bottom-width: big(1);
        }

        @include mediaMobile {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  &__dropdown {
    padding: rem(11.5) 0;

    gap: rem(11.5);

    border-bottom: solid 1px var(--stroke-dark);

    @include mediaBigDesktop {
      padding: big(11.5) 0;
      gap: big(11.5);

      border-bottom-width: big(1);
    }

    & .footer-dropdown {
      &__title {
        align-items: center;
        justify-content: start;

        text-transform: initial;
        white-space: normal;

        &::after {
          display: none;
        }

        &::before {
          @include pseudo(11.5)
        }
      }

      &__link {
        color: var(--text-dark-primary);
        text-transform: initial;
        white-space: normal;
      }

      &__list {
        gap: rem(10);

        @include mediaBigDesktop {
          gap: big(10);
        }
      }
    }
  }

  & .mm {
    &-panel {
      &::after {
        display: none;
      }

      &[data-mm-parent] {
        & .mm {
          &-navbar {
            padding: rem(20) rem(20) rem(10);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: rem(20);

            border: none;

            @include mediaBigDesktop {
              padding: big(20) big(20) big(10);
              gap: big(20);
            }

            &__title {
              padding: 0;
              margin: 0;

              display: flex;
              align-items: start;
              justify-content: start;

              color: var(--text-dark-primary);
              font-size: rem(20);
              font-weight: 600;
              line-height: 1.1;
              text-align: start;

              @include mediaBigDesktop {
                font-size: big(20);
              }
            }

            &__btn {
              top: -1px;
              right: 0;
              bottom: 0;
              left: 0;
              width: rem(16);
              height: rem(16);

              @include mediaBigDesktop {
                width: big(16);
                height: big(16);
              }

              &::before {
                content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M5.22007 7.33327L8.79607 3.75726L7.85327 2.81445L2.66781 7.99993L7.85327 13.1853L8.79607 12.2425L5.22007 8.6666H13.3345V7.33327H5.22007Z' fill='%23999999'/%3e%3c/svg%3e");
                position: unset;
                padding: 0;
                margin: 0;

                width: 100%;
                height: 100%;

                border: none;

                transform: none;
              }
            }
          }

          &-listview {
            padding: 0 rem(20);

            @include mediaBigDesktop {
              padding: 0 big(20);
            }

            & .mobile-menu {
              &__item {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &-navbar {
      display: none;
    }

    &-listitem {
      &__btn {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;

        width: rem(16);
        height: rem(16);

        border: none;
        background-color: transparent;

        @include mediaBigDesktop {
          width: big(16);
          height: big(16);
        }

        &::after {
          top: 0;
          right: 2px;
          bottom: 0;
          left: 0;
          padding: 0;
          margin: auto;
          content: "";

          width: rem(7);
          height: rem(7);

          border-width: 1px;

          @include mediaBigDesktop {
            right: big(2);

            width: big(7);
            height: big(7);

            border-width: big(1);
          }
        }

        &::before {
          @include pseudo(14)
        }
      }
    }
  }
}

.mm {
  &-wrapper {
    &__blocker {
      background-color: #000;
    }

    &--opened {
      & .mm {
        &-wrapper {
          &__blocker {
            opacity: .6;
          }
        }
      }
    }
  }
}
